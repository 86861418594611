<template>
  <div class="app">
    <div id="content" v-if="!isErr">
      <div class="con_header">{{$t('工单详情')}}</div>
      <el-row :gutter="20">
        <el-col :span="8">
          <label>{{$t('工单号')}}:</label>
          <span>{{DetailForm.wordernum}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('客户名称')}}:</label>
          <span>{{DetailForm.kehu_name}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('服务类型')}}:</label>
          <span>{{DetailForm.service_type_txt}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('联系人')}}:</label>
          <span>{{DetailForm.contacts}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('联系电话')}}:</label>
          <span>{{DetailForm.contact_number}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('归属区域')}}:</label>
          <span>{{DetailForm.rname}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('设备id')}}:</label>
          <span>{{DetailForm.equipment_id}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('报单时间')}}:</label>
          <span>{{DetailForm.declaration_time}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('派单时间')}}:</label>
          <span>{{DetailForm.dispatch_time}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('工单接收人')}}:</label>
          <span>{{DetailForm.receive_name}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('工单协助人')}}:</label>
          <span>{{DetailForm.assist_name}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('处理方式')}}:</label>
          <span>{{DetailForm.disposition_txt}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('工单状态')}}:</label>
          <span>{{DetailForm.status_txt}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('错误码')}}:</label>
          <span>{{DetailForm.fault_type}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('质保类型')}}:</label>
          <span>{{DetailForm.warranty_type_txt}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('签到时间')}}:</label>
          <span>{{DetailForm.sign_time}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('完工时间')}}:</label>
          <span>{{DetailForm.complete_time}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('完成工时')}}:</label>
          <span>{{DetailForm.complete_working_hours}}小时</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('功率')}}:</label>
          <span>{{DetailForm.power}}KW</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('经办人')}}:</label>
          <span>{{DetailForm.establish_name}}</span>
        </el-col>
        <el-col :span="8">
          <label>{{$t('评价总分')}}:</label>
          <span>{{DetailForm.fraction_total}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('详细地址')}}:</label>
          <span>{{DetailForm.detailed_address}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('服务内容')}}:</label>
          <span>{{DetailForm.service_content}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('签到备注')}}:</label>
          <span>{{DetailForm.sign_remark}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('改派原因')}}:</label>
          <span>{{DetailForm.reasons_reassignment}}</span>
        </el-col>
        <!-- <el-col :span="12">
          <label>完成情况:</label>
          <span>{{DetailForm.complete_content}}</span>
        </el-col> -->
        <el-col :span="12">
          <label>{{$t('问题详情')}}:</label>
          <span>{{DetailForm.complete_details}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('完成情况')}}:</label>
          <span>{{DetailForm.complete_content}}</span>
        </el-col>
        <el-col :span="12" v-if="DetailForm.evaluate_content">
          <label>{{$t('评价内容')}}:</label>
          <span>{{DetailForm.evaluate_content}}</span>
        </el-col>
        <el-col :span="12" v-if="DetailForm.evaluate_time">
          <label>{{$t('评价时间')}}:</label>
          <span>{{DetailForm.evaluate_time}}</span>
        </el-col>
        <el-col :span="12">
          <label>{{$t('上传图片')}}:</label>
          <el-image v-for="item in DetailForm.upload_pictures" :src="item.url" :preview-src-list="[item.url]"
            :key="item.uid">
          </el-image>
        </el-col>
        <el-col :span="12">
          <label>{{$t('完工照片')}}:</label>
          <el-image v-for="item in DetailForm.complete_pictures" :src="item.url" :preview-src-list="[item.url]"
            :key="item.uid">
          </el-image>
        </el-col>
        <el-col :span="24">
          <el-button plain @click="getDia(0)" v-if="user_role['work_order/reassignment']&&DetailForm.status_txt!=$t('完成')">{{$t('工单改派')}}</el-button>
          <el-button plain @click="getDia(1)" v-if="user_role['work_order/withdraw']">{{$t('工单撤回')}}</el-button>
          <el-button plain @click="getDia(2)" v-if="isDeactivate&&user_role['work_order/deactivate']">{{$t('启用工单')}}</el-button>
          <el-button plain @click="getDia(2)" v-if="!isDeactivate&&user_role['work_order/deactivate']">{{$t('停用工单')}}</el-button>
          <el-button plain @click="getDia(3)" v-if="user_role['work_order/setEvaluate']">{{$t('删除工单评价')}}</el-button>
        </el-col>
      </el-row>
      <!-- 改派 -->
      <el-dialog :title="$t('工单改派')" :visible.sync="isReass" width="60%">
        <el-form :model="ReassForm" :label-position="getLable"  label-width="100px">
          <el-col :span="12">
            <el-form-item :label="$t('工单接收人')">
              <el-select v-model="ReassForm.receive_uid" :placeholder="$t('请选择')">
                <el-option :label="item.username" :value="item.id" v-for="item in roleList" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('工单协助人')">
              <el-select v-model="ReassForm.assist_uid" :placeholder="$t('请选择')">
                <el-option :label="item.username" :value="item.id" v-for="item in roleList" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('改派原因')">
              <el-input
                type="textarea"
                :rows="5"
                :placeholder="$t('请输入内容')"
                show-word-limit
                :maxlength="300"
                v-model="ReassForm.reasons_reassignment">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="isReass = false">{{$t('取消')}}</el-button>
          <el-button type="primary" @click="Reass">{{$t('确定')}}</el-button>
        </div>
      </el-dialog>
      <!-- 撤回流程 -->
      <el-dialog
        :title="$t('提示')"
        :visible.sync="isWithdraw"
        width="30%">
        <span>{{$t('确定返回到上级流程吗')}}？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isWithdraw = false">{{$t('取消')}}</el-button>
          <el-button type="primary" @click="withdraw">{{$t('确定')}}</el-button>
        </span>
      </el-dialog>
      <!-- 启用停用工单 -->
      <el-dialog
        :title="$t('提示')"
        :visible.sync="isDeact"
        width="30%">
        <span>{{$t('确定执行该操作吗')}}？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isDeact = false">{{$t('取消')}}</el-button>
          <el-button type="primary" @click="Deactivate">{{$t('确定')}}</el-button>
        </span>
      </el-dialog>
      <!-- 删除评价 -->
      <el-dialog
        :title="$t('提示')"
        :visible.sync="isEvaluate"
        width="30%">
        <span>{{$t('确定删除该订单的评价吗')}}？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isEvaluate = false">{{$t('取消')}}</el-button>
          <el-button type="primary" @click="delEva">{{$t('确定')}}</el-button>
        </span>
      </el-dialog>
    </div>
    <div id="ErrPrompt" v-if="isErr">{{$t('错误')}}</div>
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'orderDet',
  mixins: [mixin],
  data () {
    return {
      isErr: false,
      DetailForm: {},
      isReass: false,
      ReassForm: {
        receive_uid: '',
        assist_uid: '',
        reasons_reassignment: ''
      },
      roleList: [],
      isWithdraw: false,
      isDeactivate: false,
      isDeact: false,
      isEvaluate: false,
      user_role: {}
    }
  },
  created () {
    // watch 路由的参数，以便再次获取数据
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$router.currentRoute.params.id) {
          var id = this.$router.currentRoute.params.id
          document.cookie = `orderId=${id}`
          this.getDet(id)
        } else {
          // this.isErr = true
          this.getDet(this.$common.getCookie('orderId'))
        }
      },
      // 组件创建完后获取数据，
      // 此时 data 已经被 observed 了
      {
        immediate: true
      }
    )
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  methods: {
    getDet (id) {
      this.$axios.post('work_order/detail', {id}).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          var data = res.data.data
          this.DetailForm = data
          this.isErr = false
        } else console.log(res)
      }).catch((error) => console.log(error))
    },
    // 按钮弹窗
    getDia (btn) {
      if (btn === 0) {
        this.isReass = true
        this.getRoleAll()
      }
      if (btn === 1) this.isWithdraw = true
      if (btn === 2) this.isDeact = true
      if (btn === 3) this.isEvaluate = true
    },
    // 获取所有角色
    getRoleAll () {
      this.$axios.post('admin/getAll').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.roleList = data.data
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 提交改派
    Reass () {
      this.$axios.post('work_order/reassignment', {
        id: this.DetailForm.id,
        receive_uid: this.ReassForm.receive_uid,
        assist_uid: this.ReassForm.assist_uid,
        reasons_reassignment: this.ReassForm.reasons_reassignment
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.$message.success(data.message)
          this.isReass = false
          this.getDet(this.DetailForm.id)
        } else { this.$message.warning(data.message) }
      }).catch((err) => console.log(err))
    },
    // 撤回流程
    withdraw () {
      this.$axios.post('work_order/withdraw', {
        id: this.DetailForm.id
      }).then((res) => {
        var data = res.data
        if (data.code === 0) {
          this.$message.success(data.message)
          this.isWithdraw = false
          this.getDet(this.DetailForm.id)
        } else { this.$message.warning(data.message) }
      }).catch((err) => console.log(err))
    },
    // 启停工单
    Deactivate () {
      var dea = null
      this.isDeactivate === true ? dea = 0 : dea = 1
      this.$axios.post('work_order/deactivate', {
        id: this.DetailForm.id,
        is_deactivate: dea
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.$message.success(data.message)
          this.isDeact = false
          this.isDeactivate === true ? this.isDeactivate = false : this.isDeactivate = true
          this.getDet(this.DetailForm.id)
        } else this.$message.warning(data.message)
      }).catch((err) => console.log(err))
    },
    // 删除评价
    delEva () {
      this.$axios.post('work_order/setEvaluate', {
        id: this.DetailForm.id,
        evaluate_content: '',
        evaluate_fraction: 5
      }).then((res) => {
        var data = res.data
        if (data.code === 0) {
          this.$message.success(data.message)
          this.isEvaluate = false
          this.getDet(this.DetailForm.id)
        } else this.$message.warning(data.message)
      }).catch((err) => console.log(err))
    }
  }
}
</script>

<style scoped>
  .con_header {
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    border-bottom: 1px solid #bfbfbf;
  }

  .el-col {
    padding: 20px 0;
    display: flex;
  }

  label {
    width: 40%;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    width: 60%;
    word-wrap: break-word;
    word-break: normal;
  }

  .el-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .el-form-item{
    width: 100%;
  }
  ::v-deep .el-select{
    width: 100% !important;
  }
</style>
